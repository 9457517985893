/* src/assets/css/ComicBookGrid.css */

:root {
  --grid-min: 20ch;
  --flex-min: 35rem;
  --pancake-min: var(--grid-min);
}

body {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(var(--grid-min), 1fr)); /* adjust minmax for your preferred size */
  gap: 16px; /* spacing between grid items */
  margin: 0 0 0 0;
  padding: 3px; /* spacing around the entire grid */
  border-radius: 11px;
  background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100%;
  background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #acacac 0, #dadada 30%, #f2f2f2 50%, #dadada 70%, #acacac 100%);
  border: 3px solid;
  border-radius: 1px;
  position: relative;
}

/* General styles for the grid container */
.grid-container {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(var(--grid-min), 1fr)); /* adjust minmax for your preferred size */
    gap: 16px; /* spacing between grid items */
    padding: 16px; /* spacing around the entire grid */
    border-radius: 11px;
    background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100%;
    background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #acacac 0, #dadada 30%, #f2f2f2 50%, #dadada 70%, #acacac 100%);
    border: 3px solid;
    border-radius: 1px;
}

.grid-container-header {
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(var(--grid-min), 1fr)); /* adjust minmax for your preferred size */
  gap: 6px; /* spacing between grid items */
  padding: 6px; /* spacing around the entire grid */
  border: 3px solid;
  border-radius: 7px;
  background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100%;
  background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #ffce55 0, #fffc83 30%, #ffffb1 50%, #fffc83 70%, #ffce55 100%);
  display: grid;
  flex-wrap: wrap;
  margin: 0.25rem 0.5rem;
  gap: .5em;
}

.grid-container-header > * {
  flex: 1 1 var(--pancake-min);
  margin: .5rem;
}

.grid-container-header.flex--auto {
  flex: 0 1 auto;
}

.grid-container-panel-header {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(var(--grid-min), 1fr)); /* adjust minmax for your preferred size */
  gap: 16px; /* spacing between grid items */
  padding: 16px; /* spacing around the entire grid */
  border: 3px solid;
  border-radius: 7px;
  background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100%;
  background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #f03485 0, #ff62b3 30%, #ff90e1 50%, #ff62b3 70%, #f03485 100%);
}

.box{
  width:100%;
  height:100%;
  background:#fff;
  border: solid black;
  border-color: black; 
}

.box2{
  width:auto;
  border-width: 3px 3px 5px 5px;
  border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
}

.evenboxinner{
  margin:15px;
  padding:4px 9px;
  float:right;
  background: rgb(255, 255, 255);
  border:1px solid #222;
  box-shadow:3px 3px 0 #222;
  font-size: 2.7em;
}

/* Styles for individual grid items */
.grid-item {
  cursor: pointer;
  display: grid;
}

/* Specific styles for grid images */
.grid-image {
  width: 100%;
  height: auto;
  border: 3px solid;
  border-radius: 5px;
  transition: transform 0.5s ease-in-out;
  box-shadow:4px 4px 0 #222;
  display: grid;
  flex-wrap: wrap;
  margin: 0.15rem 0.1rem;
  gap: 1em;
}

.grid-image > * {
  flex: 1 1 var(--pancake-min);
  margin: .5rem;
}

.grid-image.flex--auto {
  flex: 0 1 auto;
}

/* Styles for hovering over grid items */
.grid-item:hover .grid-image:hover {
  transform: scale(1.03);
}

/* Full panel container for when a panel is selected */
.full-panel-container {
  position: relative; /* to position the back button or other elements absolutely within */
  margin: 0 auto; /* center the container if it's not full width */
  max-width: 80%; /* adjust as needed, or remove if full width */
  padding: 16px;
}

/* Styles for the full panel image */
.full-panel-image {
  width: 100%;
  max-width: 100%; /* ensure the image is not larger than its container */
  height: auto;
  display: block;
  margin-bottom: 16px;
}

/* Styles for the page overview section */
.page-overview-content {
  grid-gap: 1rem;
  padding: 16px;
  border: 3px solid;
  border-radius: 7px;
  background: linear-gradient(40deg, #ffce55 0, #fffc83 30%, #ffffb1 50%, #fffc83 70%, #ffce55 100%);
  margin: .5rem;
  box-shadow: 3px 3px 0 #222;
  font-weight: 600;
  display: grid;
  flex-wrap: wrap;
  margin: 0.25rem 0.5rem;
  gap: .25em;
}

.page-overview-content > * {
  flex: 1 1 var(--pancake-min);
  margin: 1.5rem;
}

.page-overview-content.flex--auto {
  flex: 0 1 auto;
}

/* Headline styles, similar to the header style of panels */
.page-overview-headline {
  color: #222;
  font-size: 2rem;
  font-weight: 600;
  text-shadow: 2px 2px 0 #ffce55;
  margin-bottom: 0.5rem;
}

/* Body text styles, for descriptive content */
.page-overview-body {
  font-size: 1rem;
  line-height: 1.5;
  color: #000000;
  margin-bottom: 1rem;
  font-weight: 600;
}

/* Call to action button, stands out and matches the comic book theme */
.page-overview-cta {
  padding: 8px 16px;
  font-size: 1rem;
  background-color: #ffce55;
  border: 2px solid #222;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 3px 3px 0 #222;
  transition: background-color 0.3s ease;
}

.page-overview-cta:hover {
  background-color: #ffd700;
}

/* Postscript text, a smaller epilogue */
.page-overview-postscript {
  font-size: 0.9rem;
  color: #000000;
  font-style: italic;
  font-weight: 600;
}

/* Add transitions for interactive elements */
.page-overview-content, .page-overview-cta {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.page-overview-content:hover, .page-overview-cta:focus {
  transform: scale(1.02);
  box-shadow: 5px 5px 0 #222;
  margin-top: .5em;
}

/* Ensure that any clickable elements have a pointer cursor */
.page-overview-content *, .page-overview-cta {
  cursor: pointer;
}

@media (min-width: 1024px) {
  .page-overview-content {
    /* Increase the padding and max-width for larger screens */
    padding: 32px;
    max-width: 100%; /* or the max width you prefer */
    margin: .5rem auto; /* center the content with automatic margins */
    grid-template-columns: 1fr; /* if you want everything in one column */
    font-weight: 600;
  }

  .p {
    font-weight: 600;
  }

  .page-overview-headline {
    font-size: 3rem; /* Larger font size for headlines on desktop */
    margin-bottom: 1rem; /* More space below headline */
  }

  .page-overview-body {
    font-size: 1.55rem; /* Slightly larger font for body text on desktop */
    line-height: 2; /* More line height for better readability */
    margin-bottom: 2rem; /* More space below body text */
    font-weight: 600;
  }

  .page-overview-cta {
    /* Adapt the size and margins for the button */
    padding: 16px 32px; /* Larger padding */
    font-size: 1.55rem; /* Larger font size */
    margin-top: .5rem; /* Add space above the button if needed */
  }

  .page-overview-postscript {
    font-size: 1.55rem; /* Adjust postscript font size if needed */
    font-weight: 600;
  }
}

/* Add media queries for responsiveness */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(13em, 1fr)); /* smaller grid items on smaller screens */
  }

  .full-panel-container {
    max-width: 95%;
  }

  .grid-image {
    width: 95%;
  }

  .page-overview-body {
    font-size: 1.175rem; /* Slightly larger font for body text on desktop */
    line-height: 1.5; /* More line height for better readability */
    margin-bottom: 2rem; /* More space below body text */
    font-weight: 600;
  }

  .page-overview-postscript {
    font-size: 1rem; /* Adjust postscript font size if needed */
    font-weight: 600;
  }

}

/* Additional styles as needed */