/* COLOR PROFILES */
:root {
  --pink: #f03485;
  --yellow: #ffce55;
  --blue: #51cbf1;
  --orange: #fa9939;
  --green: #9dc705;
  --dkblue: #038ecf;
  --grey: #484848;
  --ltgrey: #acacac;
  --dkgrey: #222;
  --red: #f3655d;
}

/* Import fonts */
@import url('https://fonts.googleapis.com/css?family=Patrick+Hand+SC|Bangers|Happy+Monkey');

/* LAYOUT */
.whole-container {
  width: 100%;
  height: auto;
  margin: -10px;
}

section,
header {
  display: inline-block;
  width: 100%;
  height: auto;
  border: 3px solid black;
  border-radius: 5px;
  box-shadow: 4px 5px 0 #222;
}

.inner {
  max-width: 1003px;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.double,
.triple {
  max-width: 1003px;
  display: block;
}

.left {
  float: left;
}

.right {
  float: right;
}

.half {
  width: 50%;
}

.third {
  width: 33.33%;
}

.two-thirds {
  width: 66.66%;
}

/* TYPOGRAPHY */
h1, h2, h3, h4, h5 {
  font-family: 'Bangers', sans-serif;
}

p, ul, li, ol {
  font-family: 'Happy Monkey', cursive, bold;
}

.blue { color: var(--blue); }
.dkblue { color: var(--dkblue); }
.yellow { color: var(--yellow); }
.orange { color: var(--orange); }
.pink { color: var(--pink); }
.green { color: var(--green); }
.red { color: var(--red); }

/*===========================
START // Comic (Dot) Panels
============================*/

.panel {
  background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100%;
  padding-block: 10px;
}

.panel.red {
  background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #bd2d10 0, #f4502f 30%, #ff6e51 50%, #f4502f 70%, #bd2d10 100%);
}

.panel.blue {
  background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #038ecf 0, #51cbf1 30%, #7ff9ff 50%, #51cbf1 70%, #038ecf 100%);
}

.panel.grey {
  background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #484848 0, #959595 30%, #acacac 50%, #959595 70%, #484848 100%);
}

.panel.ltgrey {
  background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #acacac 0, #dadada 30%, #f2f2f2 50%, #dadada 70%, #acacac 100%);
}

.panel.yellow {
  background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #ffce55 0, #fffc83 30%, #ffffb1 50%, #fffc83 70%, #ffce55 100%);
}

.panel.orange {
  background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #fa9939 0, #ffc767 30%, #fff595 50%, #ffc767 70%, #fa9939 100%);
}

.panel.pink {
  background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #f03485 0, #ff62b3 30%, #ff90e1 50%, #ff62b3 70%, #f03485 100%);
}

.panel.green {
  background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #9dc705 0, #cbf533 30%, #f9ff61 50%, #cbf533 70%, #9dc705 100%);
}

/*===========================
END // Comic (Dot) Panels
============================*/

/* Big & "Fancy" Headlines */
.big {
  font-family: "Bangers";
  margin: 0 0 -1em 0;
  color: black;
  text-shadow: -1px -1px yellow, 1px 1px orange,  2px 2px orange;
  padding-bottom: 5px;
}

h1.big { font-size: 140px; transform: skew(8deg, 0deg); margin: 0 0 0 0; padding: 0 0 0 .5em;}
h2.big { font-size: 100px; transform: skew(8deg, 0deg); margin: 0 0 0 0; padding: 0 0 0 .5em;}
h3.big { font-size: 80px; transform: skew(8deg, 0deg); margin: 0 0 0 0; padding: 0 0 0 .5em;}
h4.big { font-size: 60px; transform: skew(8deg, 0deg); margin: 0 0 0 0; padding: 0 0 0 .5em;}

.burst {
  width: 100%;
  height: auto;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
}

.burst > .big {
  line-height: 1em;
  font-size: 3em;
  text-align: center;
}

/* Base styling for comic panels */
.comic-pane {
	max-width: 943px;
	height: auto;
	margin: 0 1%;
}

/* Shared styles for text containers */
.comic-text {
  width: 88%;
  min-height: 90%;
  position: relative;
  background: white;
  box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.1);
  border: 3px solid black;
  padding: 1em; /* Adjust as needed */
}

.text-holder {
  margin: 1.5em 2em;
  line-height: 1.2;
}

.comic-text h2 {
  font-size: 3em;
  margin-bottom: -15px;
  text-shadow: -1px -1px black, 1px 1px black, 2px 2px black;
  font-weight: 400;
  text-align: center;
}

/* Comic image styling */
.comic-image {
  width: 100%; /* Set the width to 100% of the parent container */
  height: auto; /* Height is automatic to adjust to the content */
  position: relative; /* Position relative to allow absolute positioning within it */
  display: flex;
  justify-content: center; /* Horizontally center children */
}

/* DIFFERENT LAYOUT OPTIONS */
/* Adjustments for .double layout */
.comic .double .comic-pane .text,
.comic .double .comic-pane .comic-image {
  width: 50%;
}

/* Adjustments for .triple layout */
.comic .triple .comic-pane .text,
.comic .triple .comic-pane .comic-image {
  width: 33.33%;
}

/* Special style adjustments for image-heavy variant */
.comic-pane.image-heavy .text {
  width: 10%;
}

.comic-pane.image-heavy .text:hover .caption {
  visibility: visible;
}

.comic-pane.image-heavy .comic-image {
  width: 90%;
}

/* Style for trapezoid shapes in the comic pane */
.trapezoid {
  height: 0;
  width: 43px;
  padding: 0;
  transform: skew(345deg);
  border-top: 199px solid #fff; /* Adjust the color as needed */
  border-left: 50px solid transparent;
  border-right: 0 solid transparent;
  position: absolute;
  top: 0;
  right: 73%;
}

/* Style for captions within the comic pane */
.caption {
  visibility: hidden; /* Initially hidden, will be visible on hover */
  background: rgba(255, 255, 255, 0.75);
  height: 77.75%;
  width: 77.75%;
  margin: 0 0 0 28%;
  transform: skew(-15deg);
  padding: 10%;
  position: relative; /* Added for positioning context */
}

.caption p {
  transform: skew(15deg);
  color: var(--grey); /* Use your predefined color variables */
  font-family: 'Happy Monkey', cursive; /* Keeping the font consistent */
  font-size: 1em; /* Adjust size as needed */
  line-height: 1.4; /* For better readability */
}

/* Enhancing interactivity with hover effects */
.comic-pane.image-heavy:hover .caption {
  visibility: visible; /* Show the caption on hover */
}

@media (max-width: 768px) {
  h1.big { font-size: 44px; transform: skew(8deg, 0deg); margin: 35px 10px 0 -15px; padding: 0;}
}