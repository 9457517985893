/* src/assets/css/NavPanel.css */

:root {
  --grid-min: 20ch;
  --flex-min: 35rem;
  --pancake-min: var(--grid-min);
}

.logo {
  position: fixed;
  top: 2.7em;
  left: 1.5em;
  z-index: 1;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  height: auto;
  border: 3px solid;
  border-radius: 7px;
  width: 8%; /* Adjust to your preference, it will scale proportionally */
  max-width: 15%; /* For large screens */
  min-width: 50px;
}

.logo:hover, .logo.active {
  /* Hover or active state triggers animation */
  animation: swingAndScale 1s ease-in-out forwards;
}

@keyframes swingAndScale {
  0% {
    transform: rotate(0deg) scale(1);
  }
  20% {
    transform: rotate(15deg) scale(1.1);
  }
  40% {
    transform: rotate(-10deg) scale(1.05);
  }
  60% {
    transform: rotate(5deg) scale(1.075);
  }
  80% {
    transform: rotate(-5deg) scale(1.0375);
  }
  100% {
    transform: rotate(0deg) scale(1.1);
  }
}

.comic-image-nav {
  display: flex;
  flex-wrap: wrap;
  margin: 0rem -0.5rem;
  z-index: 1;
}

.comic-image-nav > * {
  flex: 1 1 var(--pancake-min);
  margin: 0.75rem;
}

.comic-image-nav.flex--auto {
  flex: 0 1 auto;
}

.grid-container-header-nav {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(var(--grid-min), 1fr)); /* adjust minmax for your preferred size */
  gap: 16px; /* spacing between grid items */
  padding: 16px; /* spacing around the entire grid */
  border: 3px solid;
  border-radius: 7px;
  background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100%;
  background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #f03485 0, #ff62b3 30%, #ff90e1 50%, #ff62b3 70%, #f03485 100%);
}

.grid-container-header-nav-news {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem .4rem;
  border: 3px solid;
  border-radius: 7px;
  background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100%;
  background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #f03485 0, #ff62b3 30%, #ff90e1 50%, #ff62b3 70%, #f03485 100%);
}

.grid-container-header-nav-news > * {
  flex: 1 1 var(--pancake-min);
  margin: 0.5rem;
}

.grid-container-header-nav-news.flex--auto {
  flex: 0 1 auto;
}

.evenboxinner-nav{
  color: black;
  font-size: 2.6em;
  display: grid;
  flex-wrap: wrap;
  margin: 1rem 0.5rem;
  padding: 7px;
}

.evenboxinner-nav > * {
  flex: 1 1 var(--pancake-min);
  margin: 0.5rem;
}

.evenboxinner-nav.flex--auto {
  flex: 0 1 auto;
}

.nav-container {
  display: grid;
  grid-gap: 1rem;
  gap: 16px; /* spacing between grid items */
  margin: 0 0 0 0;
  padding: 3px; /* spacing around the entire grid */
  border-radius: 11px;
  position: relative;
  flex-wrap: wrap;
  padding: .5em;
}

.nav-container > * {
  flex: 1 1 var(--pancake-min);
  margin: 0.25rem;
}

.nav-container.flex--auto {
  flex: 0 1 auto;
}

.evenboxinner-nav > * {
  flex: 1 1 var(--pancake-min);
  margin: 0.5rem;
}

.evenboxinner-nav.flex--auto {
  flex: 0 1 auto;
}

@media (max-width: 768px) {
  .nav-panel {
    position: fixed;
    margin: 76px 190px 5px -2px;
    z-index: 1;
    border: 3px solid;
    border-radius: 7px;
    padding: 2px;
    display: grid;
    flex-wrap: wrap;
    margin: 6rem 0.5rem;
  }

  .nav-panel > * {
    flex: 1 1 var(--pancake-min);
    margin: 0.5rem;
  }

  .nav-panel.flex--auto {
    flex: 0 1 auto;
  }

  .nav-panel.active {
    opacity: 1;
    transform: scale(1);
    margin: 6.5rem 0.25rem;
    padding: 0.5rem;
  }

  .logo {
    width: 10%;
    top: 1.5em;
    left: 0.5em;
  }

  h1.big {
    font-size: 60px;
    transform: skew(8deg, 0deg);
    margin: 0 0px 0em -1em;
    padding: 0px 0 0 1.5em;
  }

  h2.big {
    font-size: 2.9em;
    transform: skew(8deg, 0deg);
    margin: -10px 20px -10px -50px;
    padding: 0 0 0 60px;
    line-height: 1;
  }

  .button {
    margin: .1em;
    font-size: 1.05em;
  }

  .button-inside {
    padding: none;
  }

  .nav-container {
    gap: .5em;
    margin: 0rem -0.5rem;
    padding: 0px;
  }
}
/* Additional styling for your navigation panel and items */