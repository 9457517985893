/* src/assets/styles/styles.scss */

@import url('https://fonts.googleapis.com/css?family=Patrick+Hand+SC|Bangers|Happy+Monkey');
@import url("https://fonts.googleapis.com/css?family=Dekko|Lato:900|Rock+Salt");

/* TYPOGRAPHY */
h1, h2, h3, h4, h5 {
font-family: 'Bangers', sans-serif;
margin-bottom: -15px;
margin-left: 1em;
text-shadow: -1px -1px yellow, 1px 1px orange,  2px 2px orange;
font-weight: 400;
text-align: center;
font-size: 1.7em;
}

p, ul, li, ol {
font-family: 'Happy Monkey', cursive;
text-align: center;
font-weight: 600;
}

body {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(var(--grid-min), 1fr)); /* adjust minmax for your preferred size */
    gap: 16px; /* spacing between grid items */
    margin: 0 0 0 0;
    padding: 3px; /* spacing around the entire grid */
    border-radius: 11px;
    background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, 8px 8px, 100% 100%;
    background-image: radial-gradient(ellipse farthest-corner, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 35%, #ffffff 30%, #ffffff 40%, rgba(0, 0, 0, 0) 90%), radial-gradient(ellipse farthest-corner at 0px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 8px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), radial-gradient(ellipse farthest-corner at 0px 8px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 40%), radial-gradient(ellipse farthest-corner at 8px 0px, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 20%, #ffffff 15%, #ffffff 20%, rgba(0, 0, 0, 0) 50%), linear-gradient(40deg, #acacac 0, #dadada 30%, #f2f2f2 50%, #dadada 70%, #acacac 100%);
    border: 7px solid;
    border-radius: 1px;
    position: relative;
}

.button {
--bevel: 3px;
--border-width: 3px;
font-family: 'Dekko', cursive;
font-weight: 600;
font-size: 2.2em;
background: none;
color: #000000;
filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, .95));
min-width: 6em;
border: none;
}

.button-outline {
--bevel-1: calc((var(--bevel) + (var(--border-width)) * 2) - ((var(--border-width) * #{tan(22.5deg)})) * 2);
--bevel-2: calc(var(--bevel-1) + var(--border-width));
--bevel-3: calc(var(--bevel-2) + var(--border-width));
display: block;
margin-top: calc(var(--border-width) * -1);
margin-left: calc(var(--border-width) * -1);
padding: var(--border-width);
background-color: #fff;
clip-path: polygon(
    var(--bevel-2) var(--border-width),
    calc(100% - var(--bevel-2)) var(--border-width),
    100% var(--bevel-3),
    100% calc(100% - var(--bevel-1)),
    calc(100% - var(--bevel-1)) 100%,
    var(--bevel-3) 100%,
    var(--border-width) calc(100% - var(--bevel-2)),
    var(--border-width) var(--bevel-2)
);
transition-property: clip-path;
transition-duration: .2s;

.button:hover:not(:active) &,
.button:focus-visible:not(:active) & {
    clip-path: polygon(
    var(--bevel-1) 0,
    calc(100% - var(--bevel-3)) 0,
    100% var(--bevel-3),
    100% calc(100% - var(--bevel-1)),
    calc(100% - var(--bevel-1)) 100%,
    var(--bevel-3) 100%,
    0 calc(100% - var(--bevel-3)),
    0 var(--bevel-1)
    );
}
}

.button-shadow {
--padding: calc(var(--border-width) * 2);
--bevel-1: calc((var(--bevel) + var(--border-width)) - (var(--border-width) * #{tan(22.5deg)}));
--bevel-2: calc(var(--bevel-1) + var(--border-width));
--bevel-3: calc(var(--bevel-2) + var(--border-width));
display: block;
padding: calc(var(--border-width) * 2) var(--padding) var(--padding) calc(var(--border-width) * 2) ;
background-color: #000000;
clip-path: polygon(
    var(--bevel-2) var(--border-width),
    calc(100% - var(--bevel-2)) var(--border-width),
    100% var(--bevel-3),
    100% calc(100% - var(--bevel-1)),
    calc(100% - var(--bevel-1)) 100%,
    var(--bevel-3) 100%,
    var(--border-width) calc(100% - var(--bevel-2)),
    var(--border-width) var(--bevel-2)
);
transition-property: clip-path;
transition-duration: .2s;

.button:hover:not(:active) &,
.button:focus-visible:not(:active) & {
    clip-path: polygon(
    var(--bevel-1) 0,
    calc(100% - var(--bevel-3)) 0,
    100% var(--bevel-3),
    100% calc(100% - var(--bevel-1)),
    calc(100% - var(--bevel-1)) 100%,
    var(--bevel-3) 100%,
    0 calc(100% - var(--bevel-3)),
    0 var(--bevel-1)
    );
}
}

.button-inside {
--padding-vertical: 6px;
display: block;
padding: var(--padding-vertical) 24px calc(var(--padding-vertical) - .125em);
background-color: #fff;
clip-path: polygon(
    var(--bevel) 0,
    calc(100% - var(--bevel)) 0,
    100% var(--bevel),
    100% calc(100% - var(--bevel)),
    calc(100% - var(--bevel)) 100%,
    var(--bevel) 100%,
    0 calc(100% - var(--bevel)),
    0 var(--bevel)
);
color: black;
text-align: center;
transition-property: transform;
transition-duration: .2s;

.button:hover:not(:active) &,
.button:focus-visible:not(:active) & {
    transform: translate(calc(var(--border-width) * -1), calc(var(--border-width) * -1));
}

.button:hover &,
.button:focus-visible & {
    background-color: #fcd200;
    background-image: 
    linear-gradient(to right, transparent, rgba(252, 210, 0, .9)),
    radial-gradient(#fff60d 1px, transparent 0),
    radial-gradient(#fff60d 1px, transparent 0);
    background-size: auto, 6px 6px, 6px 6px;
    background-position: 0 0, 0 0, 3px 3px;
    animation: scroll-background 1s linear infinite;
}
}

.visually-hidden {
position: absolute;
width: 1px;
height: 1px;
margin: -1px;
padding: 0;
overflow: hidden;
clip: rect(0, 0, 0, 0);
border: 0;
}

@keyframes scroll-background {
to {
    background-position-x: 0, -6px, -3px;
}
}

.button-text-characters-container {
display: inline-block;
transform: skewX(-6deg);
}

.button-text-character {
display: inline-block;

.button:hover:not(:active) &,
.button:focus-visible:not(:active) & {
    animation: jump 4s cubic-bezier(.75, .25, 1, 2) var(--delay) infinite;
}
}

@keyframes jump {
5% {
    transform: translateY(-.125em);
}
10% {
    transform: translateY(0);   
}
}

.nav-panel {
    grid-gap: 1rem;
    max-width: 100%;
    gap: 2px;
    position: fixed;
    z-index: 1;
    border: 4px solid;
    border-radius: 7px;
    background-size: 200% 200%;
    background-image: linear-gradient(60deg, #76C2AF 0%, #DE703C 20%, #D8D8D8 40%, #5A9BCF 60%, #DE4C36 80%, #EACC4A 100%);
    animation: topographyShift 10s ease infinite;
    transform-origin: top center;
    opacity: 0;
    transform: scaleX(0) scaleY(0);
    transition: transform 0.5s ease, opacity 0.5s ease;
    display: grid;
    flex-wrap: wrap;
    margin: 10rem 0.25rem;
    padding: 7px;
  
    // Nested active state with animation for each button
    &.active {
      opacity: 1;
      transform: scale(1);
      margin: 13rem 0.25rem;
      padding: .5rem;
      
      .button { // Assuming .button is the class for CustomButton components
        opacity: 0;
        transform-origin: top center;
        
        @for $i from 1 through 7 {
          &:nth-child(#{$i}) {
            animation: menu3 300ms ease-in-out forwards;
            animation-delay: ($i - 1) * 300ms;
          }
        }
      }
    }
  }
  
  // Keyframes for the background shift animation
  @keyframes topographyShift {
    0%, 100% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
  }
  
  // Keyframes for the button appearance animation
  @keyframes menu3 {
    0% {
      opacity: 0;
      transform: scale(0.3) translateY(-60px);
    }
    80% {
      transform: scale(1.2);
    }
    100% {
      opacity: 1;
      transform: scale(1) translateY(0);
    }
  }
  